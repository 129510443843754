import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { IRootState } from '@/types/reducer';

const initialState: IRootState = {
  enter: false,
  lang: 'UA',
  error: false,
  loader: {
    registration: true,
    registrationRestoran: true,
    customRestoran: true,
    language: false,
    subscribe: true,
    payment: true,
  },
  plug: false,
};

export const rootSlice = createSlice({
  name: 'rootState',
  initialState,
  reducers: {
    open: (state) => {
      state.enter = !state.enter;
    },
    changeLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setLoader: (
      state,
      action: PayloadAction<{ title: string; status: boolean }>
    ) => {
      state.loader[action.payload.title] = action.payload.status;
    },
    openPlug: (state, action: PayloadAction<boolean>) => {
      state.plug = action.payload;
    },
  },
});

export const { open, changeLang, setError, setLoader, openPlug } =
  rootSlice.actions;

export default rootSlice.reducer;
