import { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ParallaxProvider } from 'react-scroll-parallax';

import i18n from '@/i18n/i18n';
import { store } from '@/store';

import App from '@/App';
import '@/styles/global.scss';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ParallaxProvider>
          <App Component={Component} pageProps={pageProps} />
        </ParallaxProvider>
      </I18nextProvider>
    </Provider>
  );
}

export default MyApp;
