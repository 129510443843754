import { setLoader } from '@/redux/rootReducer';
import { Country } from '@/types/apiTypes';
import { IFormRegister, ILangValue } from '@/types/forms';
import { NextRouter } from 'next/router';
import { isMobile } from 'react-device-detect';
import { Dispatch } from 'redux';
import { StaticImageData } from 'next/image';

export const importVideo = (videoName: string, lang = 'uk') => {
  return require(`@/assets/video/${videoName + lang}.mp4`).default;
};

export const importImage = (imageName: string, lang = 'uk') => {
  return require(`@/assets/images/${imageName + lang}.png`).default;
};

export const importPartnersImage = (nameImage: string) => {
  return require(`@/assets/icons/partners/${nameImage}.svg`);
};

export const getImage = async (
  name: string,
  lang: string,
  theme: 'dark' | 'light',
  item: number
): Promise<StaticImageData> => {
  const image = (await import(
    `@/assets/images/themes/${theme}/${lang}/${name}-${item}.png`
  )) as unknown as Promise<StaticImageData>;

  return image;
};

export const validField = <T>(
  value: T,
  nameField: string,
  setError: (nameField: string, value: string) => void,
  error: string
) => {
  if (!value) {
    setError(nameField, error);
  } else {
    setError(nameField, '');
  }
};

export const convectorCountry = (
  array: any[],
  countryName: string | number
) => {
  return array.filter((el) => el.name === countryName)[0].id;
};

export const convectorLanguages = (langs: ILangValue[]): any => {
  return langs
    .map((el) => {
      return { default: !!el.default, label: el.label, id: +el.value };
    })
    .filter((el) => el.label)
    .map((el) => {
      return { id: el.id, default: Number(el.default) };
    });
};

export const redirect = (router: NextRouter, path: string) => {
  router.push(`/${path}`);
};

export const returnFormData = (
  arrayData: { nameKey: string; value: any }[]
) => {
  const formData = new FormData();

  arrayData.forEach((el: { nameKey: string; value: any }) => {
    formData.append(el.nameKey, el.value);
  });

  return formData;
};

export const newTenant = (
  login: string,
  country: string | number,
  timeZone: string,
  languages: any,
  countries: Country[],
  payment_code: string
) => {
  return {
    slug: login,
    name: login,
    erp: 'WithoutERP',
    sms_service: 'Infobip',
    loyalty_system: 'none',
    lite_version: 1,
    country_id: convectorCountry(countries || [], country),
    timezone: timeZone === 'Europe/Kiev' ? 'Europe/Kyiv' : timeZone,
    lingoes: languages,
    subscription_payment_code: payment_code,
  };
};

export const returnUser = (data: IFormRegister, tenatID: number) => ({
  email: data.email,
  password: data.password,
  name: data.login,
  tenant_id: tenatID,
  active: 1,
  role_names: ['eatery-lite'],
});

export const offLoadLang = (dispatch: Dispatch) => {
  setTimeout(() => {
    dispatch(setLoader({ title: 'language', status: false }));
  }, 1000);
};

export const setMobileScroll = () => {
  if (isMobile && localStorage.getItem('mobileScroll')) {
    const scroll = JSON.parse(localStorage.getItem('mobileScroll') as string);
    document.documentElement.scrollTop = scroll;
  }
};

export const formaterLogin = (login: string) => {
  return login.toLowerCase().trim().replaceAll(' ', '-').replace(/-+/g, '-');
};
