import { configureStore } from '@reduxjs/toolkit';
import RootSlice from '@/redux/rootReducer';
import FormSlise from '@/redux/formsReducer';
import PaymentReducer from '@/redux/paymentReducer';

export const store = configureStore({
  reducer: {
    rootState: RootSlice,
    formState: FormSlise,
    paymentState: PaymentReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
