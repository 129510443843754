import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { localization } from './localization';

i18n.use(initReactI18next).init(
  {
    lng: 'uk',
    resources: localization,
  },
  () => null
);

export default i18n;
