import { IPaymentData } from '@/types/reducer';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IPaymentData = {
  tenantId: 0,
  storyPayment: [],
  plans: [],
};

export const paymentSlice = createSlice({
  name: 'rootState',
  initialState,
  reducers: {
    setTenantId: (state, action: PayloadAction<number>) => {
      state.tenantId = action.payload;
    },
  },
});

export const { setTenantId } = paymentSlice.actions;

export default paymentSlice.reducer;
