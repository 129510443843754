import uk from './json/uk.json';
import en from './json/en.json';

export const localization = {
  uk: {
    translation: {
      titlePage: {
        home: uk.titleHomePage,
      },
      header: {
        enter: uk.headeEnterButton,
        add: uk.headerPlugButton,
      },
      introVideo: {
        text: uk.introVideoText,
        title: uk.introVideoTitle,
      },
      button: {
        upgrate: uk.buttonTextUpgrade,
        myself: uk.buttonTextMySelf,
        want: uk.buttonWant,
      },
      videoBlock: {
        qrTitle: uk.vidoeBlockQRTitle,
        qrDescribe: uk.videoBlocKQRDescribe,
        qrCard: uk.videoBlockQRCard,
        actionTitle: uk.videoBlockActionTitle,
        actionDescribe: uk.videonBlockActionDescribe,
        actionCard: uk.videoBlockActionCard,
        reviewTitle: uk.videoBlockReviewTitle,
        reviewDescribe: uk.videoBlockReviewDescribe,
        reviewCard: uk.videoBlockReviewCard,
      },
      costomization: {
        title: uk.blockCostomizationTitle,
        verticalBlockTitle: uk.blockCostomizationVerticalElementTitle,
        verticalBlockDescribe: uk.blockCostomizationVerticalElementDesribe,
        horizontaBlockDownLoadTitle:
          uk.blockCostomizationHorizontalElementLogoDownloadTitle,
        horizontalBlockWownLoadDescribe:
          uk.blockCostomizationHorizontalElementLogoDownloadDescribe,
        horizontalBlockRedactorTitle:
          uk.blockCostomizationHorizontalElementRedactorTitle,
        horizontalBlockRedactorDescribe:
          uk.blockCostomizationHorizontalElementRedactorDescribe,
        payMessageWait: uk.payMessageWait,
        payMessageSuccess: uk.payMessageSuccess,
        payMEssageFail: uk.payMessageFail,
      },
      onlineMenu: {
        title: uk.onlineMenuTitle,
        describe: uk.onlineMenuDescribe,
      },
      slider: {
        changed: uk.sliderChanged,
        join: uk.sliderJoin,
      },
      price: {
        title: uk.priceTitle,
        leftBlockTitle: uk.FavorableOfferTitle,
        leftBlockMonth: uk.FavorableOfferMonth,
        leftBlockRules: uk.FavorableOfferRules,
        leftBlockButton: uk.FavorableOfferButton,
        rightBlockTitle: uk.notEnoughtTitle,
        rigthBlockDescribe: uk.notEnoughtDescribe,
        rigthBlockButton: uk.notEnoughtButton,
        specialOfferTitle: uk.specialOfferTitle,
        specialOfferDescribe: uk.specialOfferDescribe,
      },
      footer: {
        question: uk.footerQuestion,
        button: uk.footerBotton,
        privacyPolicy: uk.privacyPolicy,
        termsOfUse: uk.termsOfUse,
        refundPolicy: uk.refundPolicy,
        pPolicyLink: uk.pPolicyLink,
      },
      formRegistration: {
        title: uk.formRegistrationTitle,
        loginLabel: uk.formRegistrationLoginLabel,
        loginBusyError: uk.formRegistrationLoginErrorBusy,
        loginError: uk.formRegistrationLoginError,
        emailBusy: uk.formRegistrationEmailErrorBusy,
        loginErrorFormat: uk.formRegistrationLoginErrorFormat,
        loginErrorMaxLength: uk.formRegistarionLoginErrorMaxLength,
        loginErrorMinLength: uk.formRegistrationLoginErrorMinLength,
        loginErrorDefianceStart: uk.formRegistartinLoginDefianceStart,
        loginErrorDefianceEnd: uk.formRegistrationDefianceEnd,
        passwordLabel: uk.formRegistrationPasswordTitle,
        passwordError: uk.formRegistrationPasswordError,
        passwordErrorLower: uk.formRegistrationPasswordLowerLetter,
        passwordErrorNumber: uk.formRegistrationPasswordNumber,
        passwordErrorMax: uk.formRegistrationPasswordMaxLength,
        passwordErrorMin: uk.formRegistrationPasswordMinLength,
        confirmPasswordLabel: uk.formRegisterationConfirmPasswordLabel,
        confirmPasswordPlaceholder:
          uk.formRegisterationConfirmPasswordPlaceholder,
        confirmPasswordError: uk.formRegisterationConfirmPasswordError,
        emailLabel: uk.formRegistartionEmailTitle,
        emailImportantError: uk.formRegistartionEmailError,
        emailEmpty: uk.formRegistrationEmailEmpty,
        emailCorect: uk.formRegistrationCorectEmail,
        emailErrorFormat: uk.formRegistrationEmailFormatError,
      },
      formRegistrationRestoran: {
        title: uk.formRegistrationRestoranTitle,
        defaultLangLabel: uk.formRegistrationRestoranDefaultLangLabel,
        adressLabel: uk.formRegistrationRestoranAddresLabel,
        localizationLabel: uk.formRegistrationRestoranLocalizationLabel,
        nameLabelRestoran: uk.formRegistrationRestoranNameLabel,
        nameErrorImportant: uk.formRegistrationRestoranNameErrorImportant,
        nameErrorMin: uk.formRegistrationRestoranErrorMin,
        nameErrorMax: uk.formRegistrationRestoranErrorMin,
        coutryError: uk.formRegistrationRestoranCoutryError,
        cityError: uk.formRegistrationRestoranCityError,
        cityPlaceholder: uk.formRegistrationRestoranCityPlaceholder,
        timeZoneError: uk.formRegistrationRestoranTimeZoneError,
        timeZonePlaceholder: uk.formRegistrationRestoranTimeZonePlaceholder,
        languagesError: uk.formRegistrationRestoranLanguagesError,
        languagesPlacholder: uk.formRegistrationRestoranLanguagesPlaceholder,
        regionErrorImport: uk.formRegistrationRestoranRegionErrorImport,
        regionErrorMin: uk.formRegistrationRestoranRegionErrorMin,
        regionErrorMax: uk.formRegistrationRestoranRegionErrorMax,
        regionLabel: uk.formRegistrationRestormaRegionLabel,
        countryLabel: uk.formRegistrationRestormaCountryLabel,
        countryError: uk.formRegistrationRestormaCountryError,
        streetErrorImport: uk.formRegistrationRestoranStreetErrorImport,
        streetErrorMin: uk.formRegistrationRestoranStreetErrorMin,
        streetErrorMax: uk.formRegistrationRestoranStreetErrorMax,
        streetPlaceholder: uk.formRegistrationRestoranStreetPlaceholder,
        numberHouseErrorImport:
          uk.formRegistrationRestoranNumberHouseErrorImport,
        numberHouseErrorNotSpecialSymbol:
          uk.formRegistrationRestoranNumberHouseErrorNotSpecialSymbol,
        buttonAddLang: uk.formRegistrationRestoranAddLangButton,
        buttonPrev: uk.back,
        buttonNext: uk.formRegistrationRestoranNextButton,
      },
      formCustomizationRestoran: {
        title: uk.formCustomThemesTitle,
        logoLabel: uk.formCustomThemeLabelLogo,
        logoLoad: uk.formCustomThemeLoadLogo,
        format: uk.formCustomThemeFormat,
        shemaLabel: uk.formCustomThemeShemaColorLabel,
        lightShema: uk.formatCustomThemeLightTheme,
        darkShema: uk.formatCustomThemeDarkTheme,
        buttonBack: uk.back,
        buttonReload: uk.formatCustomThemeButtonReload,
        buttonNext: uk.formatCustomThemeButtonNext,
        alarm: uk.formCustomThemeAlarm,
      },
      formForgotPassword: {
        title: uk.forgotPasswordTitle,
        emptyEmailError: uk.forgotPasswordEmailEmptyError,
        invalidEmail: uk.forgotPasswordInvalidEmail,
        emailButton: uk.forgotPasswordButtonEmail,
        topSuccess: uk.successEmailTop,
        bottomSuccess: uk.successEmailBottom,
        reload: uk.reloadForm,
        successPassword: uk.successPassword,
        successPasswordButton: uk.successPasswordButton,
      },
      subscribe: {
        title: uk.subscribeFormTitle,
        status: uk.subscribeStatus,
        timeStatus: uk.subscribeStatusTime,
        auto: uk.autoSubscribe,
        stop: uk.stopSubscribe,
        cancel: uk.cancelSubscribe,
        paymentLabel: uk.paymentLabel,
        mounthLabel: uk.paymentMounthLabel,
        mounth: uk.subscribeMounthName,
        yearPay: uk.subscribeYear,
        pay: uk.pay,
        accountAdminLink: uk.accountAdminLink,
        accountOrderSiteLink: uk.accountOrderSiteLink,
        accountQRLink: uk.accountQRLink,
        accountQRDownload: uk.accountQRDownload,
        linksTitle: uk.linksTitle,
        support: uk.support,
        supportDocs: uk.supportDocs,
        emailToSupport: uk.emailToSupport,
        supportLink: uk.supportLink,
        exit: uk.exit,
        copy: uk.copy,
        download: uk.download,
        copyLink: uk.copyLink,
        renewSubscribe: uk.subscribeRenewSubscription,
      },
      404: {
        describe: uk.somethingWrong,
        button: uk.back,
        title: uk[404],
      },
      enterForm: {
        title: uk.enterFormLabel,
        buttonRegister: uk.enterFormRegistrationButton,
        labelEmail: uk.enterFormLabelEmail,
        forgotPassword: uk.forgotPassword,
      },
      errorForm: {
        title: uk.errorFormTitle,
        action: uk.errorFormAction,
      },
      payment: {
        title: uk.paymentTitle,
        addCard: uk.paymentAddCard,
        nameTitle: uk.paymentNameTitle,
        namePlaceholder: uk.paymentNamePlaceholder,
        cardTitle: uk.paymentNumberCardTitle,
        cardPlaceholder: uk.paymentNumberCardTitle,
        dateTitle: uk.paymentDateTitle,
        datePlaceholder: uk.paymentDatePlaceholder,
        cvvTitle: uk.paymentCVV,
        cvvPlaceholder: uk.paymentCVVPlaceholder,
        pay: uk.paymentButtonPay,
        back: uk.back,
        error: uk.payError,
      },
      plug: {
        describe: uk.plugDescribe,
      },
    },
  },
  en: {
    translation: {
      titlePage: {
        home: en.titleHomePage,
      },
      header: {
        enter: en.headeEnterButton,
        add: en.headerPlugButton,
      },
      introVideo: {
        text: en.introVideoText,
        title: en.introVideoTitle,
      },
      button: {
        upgrate: en.buttonTextUpgrade,
        myself: en.buttonTextMySelf,
        want: en.buttonWant,
      },
      videoBlock: {
        qrTitle: en.vidoeBlockQRTitle,
        qrDescribe: en.videoBlocKQRDescribe,
        qrCard: en.videoBlockQRCard,
        actionTitle: en.videoBlockActionTitle,
        actionDescribe: en.videonBlockActionDescribe,
        actionCard: en.videoBlockActionCard,
        reviewTitle: en.videoBlockReviewTitle,
        reviewDescribe: en.videoBlockReviewDescribe,
        reviewCard: en.videoBlockReviewCard,
      },
      costomization: {
        title: en.blockCostomizationTitle,
        verticalBlockTitle: en.blockCostomizationVerticalElementTitle,
        verticalBlockDescribe: en.blockCostomizationVerticalElementDesribe,
        horizontaBlockDownLoadTitle:
          en.blockCostomizationHorizontalElementLogoDownloadTitle,
        horizontalBlockWownLoadDescribe:
          en.blockCostomizationHorizontalElementLogoDownloadDescribe,
        horizontalBlockRedactorTitle:
          en.blockCostomizationHorizontalElementRedactorTitle,
        horizontalBlockRedactorDescribe:
          en.blockCostomizationHorizontalElementRedactorDescribe,
        payMessageWait: en.payMessageWait,
        payMessageSuccess: en.payMessageSuccess,
        payMEssageFail: en.payMessageFail,
      },
      onlineMenu: {
        title: en.onlineMenuTitle,
        describe: en.onlineMenuDescribe,
      },
      slider: {
        changed: en.sliderChanged,
        join: en.sliderJoin,
      },
      price: {
        title: en.priceTitle,
        leftBlockTitle: en.FavorableOfferTitle,
        leftBlockMonth: en.FavorableOfferMonth,
        leftBlockRules: en.FavorableOfferRules,
        leftBlockButton: en.FavorableOfferButton,
        rightBlockTitle: en.notEnoughtTitle,
        rigthBlockDescribe: en.notEnoughtDescribe,
        rigthBlockButton: en.notEnoughtButton,
        specialOfferTitle: en.specialOfferTitle,
        specialOfferDescribe: en.specialOfferDescribe,
      },
      footer: {
        question: en.footerQuestion,
        button: en.footerBotton,
        privacyPolicy: en.privacyPolicy,
        termsOfUse: en.termsOfUse,
        refundPolicy: en.refundPolicy,
        pPolicyLink: en.pPolicyLink,
      },
      formRegistration: {
        title: en.formRegistrationTitle,
        loginLabel: en.formRegistrationLoginLabel,
        loginError: en.formRegistrationLoginError,
        loginBusyError: en.formRegistrationLoginErrorBusy,
        emailBusy: en.formRegistrationEmailErrorBusy,
        loginErrorFormat: en.formRegistrationLoginErrorFormat,
        loginErrorMaxLength: en.formRegistarionLoginErrorMaxLength,
        loginErrorMinLength: en.formRegistrationLoginErrorMinLength,
        loginErrorDefianceStart: en.formRegistartinLoginDefianceStart,
        loginErrorDefianceEnd: en.formRegistrationDefianceEnd,
        passwordLabel: en.formRegistrationPasswordTitle,
        passwordError: en.formRegistrationPasswordError,
        passwordErrorLower: en.formRegistrationPasswordLowerLetter,
        passwordErrorNumber: en.formRegistrationPasswordNumber,
        passwordErrorMax: en.formRegistrationPasswordMaxLength,
        passwordErrorMin: en.formRegistrationPasswordMinLength,
        confirmPasswordLabel: en.formRegisterationConfirmPasswordLabel,
        confirmPasswordPlaceholder:
          en.formRegisterationConfirmPasswordPlaceholder,
        confirmPasswordError: en.formRegisterationConfirmPasswordError,
        emailLabel: en.formRegistartionEmailTitle,
        emailImportantError: en.formRegistartionEmailError,
        emailEmpty: en.formRegistrationEmailEmpty,
        emailCorect: uk.formRegistrationCorectEmail,
        emailErrorFormat: en.formRegistrationEmailFormatError,
      },
      formRegistrationRestoran: {
        title: en.formRegistrationRestoranTitle,
        defaultLangLabel: en.formRegistrationRestoranDefaultLangLabel,
        adressLabel: en.formRegistrationRestoranAddresLabel,
        localizationLabel: en.formRegistrationRestoranLocalizationLabel,
        nameLabelRestoran: en.formRegistrationRestoranNameLabel,
        nameErrorImportant: en.formRegistrationRestoranNameErrorImportant,
        nameErrorMin: en.formRegistrationRestoranErrorMin,
        nameErrorMax: en.formRegistrationRestoranErrorMin,
        coutryError: en.formRegistrationRestoranCountryError,
        cityError: en.formRegistrationRestoranCityError,
        cityPlaceholder: en.formRegistrationRestoranCityPlaceholder,
        timeZoneError: en.formRegistrationRestoranTimeZoneError,
        timeZonePlaceholder: en.formRegistrationRestoranTimeZonePlaceholder,
        languagesError: en.formRegistrationRestoranLanguagesError,
        languagesPlacholder: en.formRegistrationRestoranLanguagesPlaceholder,
        regionErrorImport: en.formRegistrationRestoranRegionErrorImport,
        regionErrorMin: en.formRegistrationRestoranRegionErrorMin,
        regionErrorMax: en.formRegistrationRestoranRegionErrorMax,
        regionLabel: en.formRegistrationRestormaRegionLabel,
        countryLabel: en.formRegistrationRestormaCountryLabel,
        countryError: en.formRegistrationRestormaCountryError,
        streetErrorImport: en.formRegistrationRestoranStreetErrorImport,
        streetErrorMin: en.formRegistrationRestoranStreetErrorMin,
        streetErrorMax: en.formRegistrationRestoranStreetErrorMax,
        streetPlaceholder: en.formRegistrationRestoranStreetPlaceholder,
        numberHouseErrorImport:
          en.formRegistrationRestoranNumberHouseErrorImport,
        numberHouseErrorNotSpecialSymbol:
          en.formRegistrationRestoranNumberHouseErrorNotSpecialSymbol,
        buttonAddLang: en.formRegistrationRestoranAddLangButton,
        buttonPrev: en.back,
        buttonNext: en.formRegistrationRestoranNextButton,
      },
      formCustomizationRestoran: {
        title: en.formCustomThemesTitle,
        logoLabel: en.formCustomThemeLabelLogo,
        logoLoad: en.formCustomThemeLoadLogo,
        format: en.formCustomThemeFormat,
        shemaLabel: en.formCustomThemeShemaColorLabel,
        lightShema: en.formatCustomThemeLightTheme,
        darkShema: en.formatCustomThemeDarkTheme,
        buttonBack: en.back,
        buttonReload: en.formatCustomThemeButtonReload,
        buttonNext: en.formatCustomThemeButtonNext,
        alarn: en.formCustomThemeAlarm,
      },
      formForgotPassword: {
        title: en.forgotPasswordTitle,
        emptyEmailError: en.forgotPasswordEmailEmptyError,
        invalidEmail: en.forgotPasswordInvalidEmail,
        emailButton: en.forgotPasswordButtonEmail,
        topSuccess: en.successEmailTop,
        bottomSuccess: en.successEmailBottom,
        reload: en.reloadForm,
        successPassword: en.successPassword,
        successPasswordButton: en.successPasswordButton,
      },
      subscribe: {
        title: en.subscribeFormTitle,
        status: en.subscribeStatus,
        timeStatus: en.subscribeStatusTime,
        auto: en.autoSubscribe,
        stop: en.stopSubscribe,
        cancel: en.cancelSubscribe,
        paymentLabel: en.paymentLabel,
        mounthLabel: en.paymentMounthLabel,
        mounth: en.subscribeMounthName,
        yearPay: en.subscribeYear,
        pay: en.pay,
        accountAdminLink: en.accountAdminLink,
        accountOrderSiteLink: en.accountOrderSiteLink,
        accountQRLink: en.accountQRLink,
        accountQRDownload: en.accountQRDownload,
        linksTitle: en.linksTitle,
        support: en.support,
        supportDocs: en.supportDocs,
        emailToSupport: en.emailToSupport,
        supportLink: en.supportLink,
        exit: en.exit,
        copy: en.copy,
        download: en.download,
        copyLink: en.copyLink,
        renewSubscribe: en.subscribeRenewSubscription,
      },
      404: {
        describe: en.somethingWrong,
        button: en.back,
        title: uk[404],
      },
      enterForm: {
        title: en.enterFormLabel,
        buttonRegister: en.enterFormRegistrationButton,
        labelEmail: en.enterFormLabelEmail,
        forgotPassword: en.forgotPassword,
      },
      errorForm: {
        title: en.errorFormTitle,
        action: en.errorFormAction,
      },
      payment: {
        title: en.paymentTitle,
        addCard: en.paymentAddCard,
        nameTitle: en.paymentNameTitle,
        namePlaceholder: en.paymentNamePlaceholder,
        cardTitle: en.paymentNumberCardTitle,
        cardPlaceholder: en.paymentNumberCardPlaceholder,
        dateTitle: en.paymentDateTitle,
        datePlaceholder: en.paymentDatePlaceholder,
        cvvTitle: en.paymentCVV,
        cvvPlaceholder: en.paymentCVVPlaceholder,
        pay: en.paymentButtonPay,
        back: en.back,
        error: en.payError,
      },
      plug: {
        describe: en.plugDescribe,
      },
    },
  },
};
