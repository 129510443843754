import { convectorCountry, convectorLanguages } from '@/helper/functions';
import {
  IDate,
  IFormRegister,
  IFormRegistrationRestoran,
  ILangValue,
} from '@/types/forms';
import { IFormsState } from '@/types/reducer';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IFormsState = {
  formRegistration: {
    login: '',
    password: '',
    email: '',
  },
  formRegistrationRestoran: {
    name: '',
    country: '',
    timeZone: '',
    region: '',
    city: '',
    street: '',
    number: '',
    languages: [],
  },
  formTheme: {
    colorTheme: 0,
    logo: {},
  },
  countries: [],
  load: false,
};

export const formsSlice = createSlice({
  name: 'formsSlice',
  initialState,
  reducers: {
    setFormRegistration: (state, action: PayloadAction<IFormRegister>) => {
      state.formRegistration = { ...state.formRegistration, ...action.payload };
    },
    setFormRegistrationRestoran: (
      state,
      action: PayloadAction<IFormRegistrationRestoran>
    ) => {
      action.payload.languages = convectorLanguages(
        action.payload.languages as unknown as ILangValue[]
      );
      state.formRegistrationRestoran = {
        ...state.formRegistrationRestoran,
        ...action.payload,
      };
    },
    setFormTheme: (state, action: PayloadAction<number>) => {
      state.formTheme = { ...state.formTheme, colorTheme: action.payload };
    },
    setFormThemeLogo: (state, action: PayloadAction<FormData>) => {
      state.formTheme = { ...state.formTheme, logo: action.payload };
    },
    setCountrys: (state, action: PayloadAction<IDate[]>) => {
      state.countries = action.payload;
    },
    setLoad: (state, action: PayloadAction<boolean>) => {
      state.load = action.payload;
    },
  },
});

export const {
  setFormRegistration,
  setFormRegistrationRestoran,
  setCountrys,
  setFormTheme,
  setFormThemeLogo,
  setLoad,
} = formsSlice.actions;

export default formsSlice.reducer;
